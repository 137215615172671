import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './homepage'; // Adjust the path as necessary
import PrivacyPolicy from './privacy-policy';
import CP from './copyright-Policy';


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/copyright" element={<CP/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
