import React from 'react';
import './pp.css';

const PrivacyPolicy = () => {
    return (
      <div className="privacy-policy">
        <h1>Privacy Policy for ChitChatX</h1>
        <p>Last Updated: 25 March 2024</p>
        
        <section>
          <h2>Introduction</h2>
          <p>Welcome to ChitChatX! Your privacy is of paramount importance to us. This Privacy Policy outlines the types of information we collect, how it's used, and the measures we take to protect your personal data.</p>
        </section>
        
        <section>
          <h2>Information Collection</h2>
          <ul>
            <li><strong>User-Provided Information:</strong> This includes account registration details like your name, email address, and any other information you provide.</li>
            <li><strong>Communication Data:</strong> Messages, call logs, and file transfers are encrypted and not accessible to us.</li>
            <li><strong>Usage Data:</strong> We collect anonymized data on app usage to improve our service.</li>
          </ul>
        </section>
        
        <section>
          <h2>Use of Information</h2>
          <p>The information collected is used to:</p>
          <ul>
            <li>Provide, maintain, and improve ChitChatX services.</li>
            <li>Communicate with you about updates or issues.</li>
            <li>Enhance app security and user experience.</li>
          </ul>
        </section>
        
        <section>
          <h2>Data Storage and Security</h2>
          <ul>
            <li><strong>Encryption:</strong> All communications are end-to-end encrypted.</li>
            <li><strong>Data Storage:</strong> Data is stored securely with access limited to authorized personnel.</li>
            <li><strong>Security Measures:</strong> We implement industry-standard security measures to protect against unauthorized access or data breaches.</li>
          </ul>
        </section>
        
        <section>
          <h2>Data Sharing and Disclosure</h2>
          <p>We do not sell or share your personal information with third parties, except:</p>
          <ul>
            <li>To comply with legal obligations.</li>
            <li>To protect the rights and safety of ChitChatX and its users.</li>
          </ul>
        </section>
        
        <section>
          <h2>User Rights</h2>
          <p>You have the right to access, update, or delete your personal information. You can also object to or restrict certain processing of your data.</p>
        </section>
  
        <section>
          <h2>International Data Transfers</h2>
          <p>Your information may be transferred to — and maintained on — computers located outside of your state or country, where the data protection laws may differ.</p>
        </section>
  
        <section>
          <h2>Data Access Permissions</h2>
          <p>ChitChatX respects your privacy and will request permission before accessing your data such as photos, videos, or any other sensitive information. Without your permission, the app will not access such data. Additionally, any data you send to others through the app is peer-to-peer secured.</p>
        </section>
  
        <section>
          <h2>Changes to This Policy</h2>
          <p>We may update our Privacy Policy periodically. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
        </section>
        
        <section>
          <h2>Contact Us</h2>
          <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:support@p2pchatter.com">support@p2pchatter.com</a>.</p>
        </section>
      </div>
    );
  };

export default PrivacyPolicy;
