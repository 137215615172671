import React from 'react';
import { Link } from 'react-router-dom';

const HomePage = () => {
  return (
    <div className="homepage">
      <header>
        <h1>CCX</h1>
        <nav>
          <ul className="navigation">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/copyright">Copyright</Link></li>
            <li><Link to="/support">Support</Link></li>
          </ul>
        </nav>
      </header>
      <main>
        <section className="welcome-section">
          <h2>Welcome to CCX!</h2>
          <p>Experience the ultimate communication platform where privacy meets innovation. Engage in seamless conversations, share moments, and create memories that last a lifetime.</p>
        </section>
        <section className="features-section">
          <h3>Features:</h3>
          <ul>
            <li>Instant Messaging: Fast, reliable, and secure messaging at your fingertips.</li>
            <li>Group Chats: Create or join groups to share interests, coordinate work, or stay connected with friends and family.</li>
            <li>Voice & Video Calls: Crystal-clear voice and high-quality video calls to stay connected, anywhere, anytime.</li>
            <li>Photo & Video Sharing: Share your moments through high-quality images and videos.</li>
            <li>Customizable Themes: Personalize your chat experience with customizable themes and backgrounds.</li>
          </ul>
        </section>
        <section className="download-section">
          <h3>Join CCX Today</h3>
          <p>Download the app and start your journey towards seamless communication and endless possibilities. Connect. Share. Discover. with CCX.</p>
        </section>
      </main>
      <footer>
        <p>Follow us on social media for updates, tips, and the latest news on CCX. Join our community and be part of the conversation.</p>
      </footer>
    </div>
  );
};

export default HomePage;
