import React from 'react';
import './cp.css';


const CP = () => {
  return (
    <div className="cp">
      <h1>Privacy Policy for Chit Chat X</h1>
      <p>Last updated: 26 March 2024</p>
      
      <section>
        <h2>Copyright and DMCA Policy</h2>
        <p>References to "ChitChatX", "we", or "us" in this document refer to ChitChatX (a trade name of ML Ltd), including its subsidiaries: ChitChatX Software SARL (France), ChitChatX Software Inc (U.S.), ChitChatX Software (Germany), and their respective agents.</p>
      </section>
      
      <section>
        <p>"The homeserver" and "the Service" mentioned here refer to the services available at p2pchatter Matrix Services (PMS).

If you disagree with these terms, we encourage the use of a Matrix server from a different provider.

Copyright Infringement Claims ChitChatX treats copyright infringement claims with utmost seriousness. This section explains how to report alleged infringements and our policy to deactivate accounts of habitual infringers. To report alleged infringements accessible through the Service, please provide a written notice to our designated Copyright Agent (details below) that includes:

Your signature (physical or electronic). Identification of the copyrighted work you believe to be infringed. Precise identification of the allegedly infringing material. Your contact information (name, address, phone number, email). A statement of good faith belief that the material is not authorized by the copyright owner. A declaration that the information in the notice is accurate. A statement, under penalty of perjury, that you are authorized to represent the copyright owner.

Designated Copyright Agent: Zohaib Latif Qureshi ML LTD 2603 JBC-5 JLT DUBAI Email: dmca@p2pchatter.com

Ineffective DMCA Notices may result from non-compliance with Section 512(c)(3) of the DMCA. False claims can lead to liability for damages under Section 512(f) of the DMCA.

Counter-Notification Procedures If you believe your content was mistakenly removed or access disabled, you may submit a Counter-Notice to our Copyright Agent. It should include: Your signature (physical or electronic). Identification of the removed/disabled material and its previous location. Your contact information (name, address, phone number, email). A statement under penalty of perjury that the removal/disabling was a mistake or misidentification. Agreement to the jurisdiction of the Federal District Court in your location and acceptance of service from the complainant. Content may be restored if the complainant does not take legal action within ten business days of receiving your Counter-Notice.

False counter-claims can result in liability for damages under Section 512(f) of the DMCA.

Policy on Repeat Infringers In certain cases, ChitChatX will disable or terminate accounts of users who repeatedly infringe copyrights.</p>
       
      </section>
      

    </div>
  );
};

export default CP;
